import './components/aos';
import './components/headroom';
import coSmoothScroll from './components/smooth-scroll';
import coContactForm from './components/contact-form';

(() => {
  if (typeof $ === 'undefined') {
    throw new TypeError('This JavaScript package requires jQuery. jQuery must be included before this package.');
  }
})();

export {
  coSmoothScroll,
  coContactForm,
};

(($) => {
  const fn = {
    // Launch Functions
    Launch: () => {
      fn.Masonry();
      fn.ImageView();
      // fn.Typed();
      fn.Swiper();
      fn.Vivus();
      fn.Overlay();
      fn.OwlCarousel();
      fn.Apps();
    },

    ImageView: () => {
      $('.lightbox').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos: true,
        mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
        image: {
          verticalFit: true,
        },
      });

      $('.gallery').each((index, element) => { // the containers for all your galleries
        $(element).magnificPopup({
          delegate: '.photo > a', // the selector for gallery item
          type: 'image',
          mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
          gallery: {
            enabled: true,
          },
        });
      });

      // $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      //   disableOn: 700,
      //   type: 'iframe',
      //   mainClass: 'mfp-fade',
      //   removalDelay: 160,
      //   preloader: false,
      //   fixedContentPos: false,
      // });
    },

    // Typed: () => {
    //   if ($('#typed').length) {
    //     // eslint-disable-next-line
    //     const typed = new Typed('#typed', {
    //       stringsElement: '#typed-strings',
    //       typeSpeed: 100,
    //       backSpeed: 50,
    //       backDelay: 2000,
    //       startDelay: 200,
    //       loop: true,
    //     });
    //   }
    // },

    Masonry: () => {
      const $grid = $('.masonry').masonry({
        itemSelector: '.masonry > *',
      });

      $grid.imagesLoaded().progress(() => {
        $grid.masonry('layout');
      });
    },


    // Swiper
    Swiper: () => {
      $('.swiper-container').each((index, element) => {
        const $this = $(element);

        $this.find('.swiper-pagination').addClass(`swiper-pagination-${index}`);
        $this.find('.swiper-button-next').addClass(`swiper-button-next-${index}`);
        $this.find('.swiper-button-prev').addClass(`swiper-button-prev-${index}`);

        const options = {
          parallax: true,
          speed: 1500,
          simulateTouch: false,
          effect: 'fade',

          // pagination
          pagination: {
            el: `.swiper-pagination-${index}`,
            clickable: true,
          },

          // navigation
          navigation: {
            nextEl: `.swiper-button-next-${index}`,
            prevEl: `.swiper-button-prev-${index}`,
          },
        };

        const slider = $(element);

        if ($(element).hasClass('swiper-container-carousel')) {
          options.spaceBetween = 10;
          options.effect = 'slide';
          options.simulateTouch = true;
          options.slideToClickedSlide = true;
        }

        // eslint-disable-next-line
        const swiper = new Swiper(slider, options);
      });


      if ($('.gallery-container').length) {
        // eslint-disable-next-line
        const galleryTop = new Swiper('.gallery-container', {
          effect: 'fade',
          speed: 1500,
          simulateTouch: false,
        });
        // eslint-disable-next-line
        const galleryThumbs = new Swiper('.gallery-thumbs', {
          centeredSlides: true,
          slidesPerView: 6,
          speed: 1500,
          breakpoints: {
            1600: { slidesPerView: 5 },
            1200: { slidesPerView: 3 },
            768: { slidesPerView: 2 },
            576: { slidesPerView: 2 },
          },
          slideToClickedSlide: true,
        });
        galleryTop.controller.control = galleryThumbs;
        galleryThumbs.controller.control = galleryTop;
      }
    },


    // SVG Animation
    Vivus: () => {
      const myCallback = () => {};

      const myElements = document.querySelectorAll('.svg-icon svg');

      for (let i = myElements.length - 1; i >= 0; i -= 1) {
        // eslint-disable-next-line
        const vivus = new Vivus(myElements[i], { duration: 100, type: 'async' }, myCallback);
      }
    },


    // Overlay Menu
    Overlay: () => {
      $(document).ready(() => {
        $('.burger').click(() => {
          const a = $(this);
          a.toggleClass('clicked');
          $('body').toggleClass('overlay-active');
          $('.overlay-menu').toggleClass('opened');
          $('.wrapper').toggleClass('push');
        });
      });
    },


    // Owl Carousel
    OwlCarousel: () => {
      $('.owl-carousel').each((index, element) => {
        const a = $(element);
        const items = a.data('items') || [1, 1, 1];
        const margin = a.data('margin');
        const loop = a.data('loop');
        const nav = a.data('nav');
        const dots = a.data('dots');
        const center = a.data('center');
        const autoplay = a.data('autoplay');
        const autoplaySpeed = a.data('autoplay-speed');
        const rtl = a.data('rtl');
        const autoheight = a.data('autoheight');

        const options = {
          nav: nav || false,
          loop: loop || false,
          dots: dots || false,
          center: center || false,
          autoplay: autoplay || false,
          autoHeight: autoheight || false,
          rtl: rtl || false,
          margin: margin || 0,
          autoplayTimeout: autoplaySpeed || 3000,
          autoplaySpeed: 400,
          autoplayHoverPause: true,
          responsive: {
            0: { items: items[2] || 1 },
            576: { items: items[1] || 1 },
            1200: { items: items[0] || 1 },
          },
        };

        a.owlCarousel(options);
      });
    },

    // Apps
    Apps: () => {
      // Navbar Nested Dropdowns
      $('.dropdown-menu a.dropdown-toggle').on('click', () => {
        const a = $(this);

        if (!a.next().hasClass('show')) {
          a.parents('.dropdown-menu').first().find('.show').removeClass('show');
        }

        const $subMenu = a.next('.dropdown-menu');
        $subMenu.toggleClass('show');

        a.parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', () => {
          $('.dropdown-submenu .show').removeClass('show');
        });

        return false;
      });


      // Accordion
      $('.accordion').accordion();


      // Lavalamp
      $('.nav').lavalamp({
        setOnClick: true,
        enableHover: false,
        margins: false,
        autoUpdate: true,
        duration: 200,
      });


      // Tooltips
      $('[data-toggle="tooltip"]').tooltip();

      // eslint-disable-next-line
      skrollr.init({
        forceHeight: false,
        mobileCheck: () => false,
      });


      // // Video Player
      // $(() => {
      //   if ($('#my-player').length) {
      //     // eslint-disable-next-line
      //     videojs('my-player');
      //   }
      // });


      // Select
      $(() => {
        $('select').selectric({
          disableOnMobile: false,
          nativeOnMobile: false,
        });
      });


      // Radial
      $('.progress-circle').each((index, element) => {
        const a = $(element);
        const b = a.data('percent');
        const c = a.data('color');

        const options = {
          value: (b / 100),
          size: 600,
          thickness: 15,
          startAngle: -Math.PI / 2,
          lineCap: 'round',
          fill: {
            color: c,
          },
        };

        a.circleProgress(options).on('circle-animation-progress', (event, progress, stepValue) => {
          const displayVal = Math.round(100 * stepValue);
          a.find('strong').html(`${displayVal}<i>%</i>`);
        });
      });

      // Smooth Scroll
      $(() => {
        // eslint-disable-next-line
        const smoothscroll = new SmoothScroll('[data-scroll]');
      });


      // Filtering
      $(() => {
        if ($('.filtr-container').length) {
          $('.filtr-container').filterizr({
            layout: 'sameWidth',
          });
        }
      });


      // Speaker Panel
      $(() => {
        const a = $('.expand');
        const b = $('.collapse');

        a.click(() => {
          const c = $(this);
          a.removeClass('expanded');
          c.toggleClass('expanded');
        });

        b.click(() => {
          a.removeClass('expanded');
        });
      });
    },
  };

  $(document).ready(() => {
    fn.Launch();
  });
})(jQuery);
