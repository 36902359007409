import jQuery from 'jquery';
import SmoothScroll from 'smooth-scroll';

const coSmoothScroll = (($) => {
  const smoothScroll = new SmoothScroll('a[data-smooth-scroll]',
    {
      offset: $('body').attr('data-smooth-scroll-offset') || 0,
    });
  return smoothScroll;
})(jQuery);

export default coSmoothScroll;
