import jQuery from 'jquery';

const coContactForm = (($) => {
  const NAME = 'coContactForm';
  const JQUERY_NO_CONFLICT_NAME = $.fn[NAME];
  const DATA_KEY = 'co.contactForm';
  const EVENT_KEY = `.${DATA_KEY}`;
  const SELECT_QUERIES = {
    FORM: '[data-form-contact]',
    FORM_INPUTS: 'input,textarea,select',
    MESSAGE_SUCCESS: '[data-success-message]',
    MESSAGE_ERROR: '[data-error-message]',
    SUBMIT_BUTTON: 'button[type="submit"]',
    SUBMIT_TEXT: 'span',
  };
  const CLASSES = {
    HIDE: 'd-none',
    SHOW: 'd-block',
    WAS_VALIDATED: 'was-validated',
    LOADING: 'btn-loading-animate',
  };
  const ATTRIBUTES = {
    FORM_ACTION: 'action',
    FEEDBACK_DELAY: 'data-feedback-delay',
    SUBMIT_DISABLED: 'disabled',
    SUBMIT_LOADING_TEXT: 'data-loading-text',
    SUCCESS_REDIRECT: 'data-success-redirect',
    SUCCESS_DOWNLOAD: 'data-success-download',
  };
  const DEFAULTS = {
    FORM_ACTION: '/',
    FEEDBACK_DELAY: 5000,
    SUBMIT_LOADING_TEXT: 'Sending...',
    ERROR_TEXT: 'Form submission error',
  };
  const STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
  };
  const EVENTS = {
    LOAD_DATA_API: `load${EVENT_KEY}.data-api`,
    SENT: `sent${EVENT_KEY}`,
    SUBMIT: 'submit',
  };

  class ContactForm {
    constructor(element) {
      this.form = element;
      this.action = this.form.getAttribute(ATTRIBUTES.FORM_ACTION) || DEFAULTS.FORM_ACTION;
      this.initFeedbackElements();
      this.initSubmitButton();
      this.initSubmitEvent();
    }

    // Initializes the elements that handle feedback messages.
    initFeedbackElements() {
      if (!this.feedback) {
        this.feedback = {
          success: this.form.querySelector(SELECT_QUERIES.MESSAGE_SUCCESS),
          error: this.form.querySelector(SELECT_QUERIES.MESSAGE_ERROR),
        };
        this.feedbackErrorMessage = this.feedback.error.innerHTML;
        this.feedbackSuccessMessage = this.feedback.success.innerHTML;
        const feedbackDelay = this.form.getAttribute(ATTRIBUTES.FEEDBACK_DELAY)
                            || DEFAULTS.FEEDBACK_DELAY;
        this.feedbackDelay = parseInt(feedbackDelay, 10);
        this.feedbackTimeout = null;
      }
      return this.feedback;
    }

    // Initializes the submit button and its text elements.
    initSubmitButton() {
      if (!this.submitButton) {
        this.submitButton = this.form.querySelector(SELECT_QUERIES.SUBMIT_BUTTON);
      }
      this.submitButtonText = this.submitButton.querySelector(SELECT_QUERIES.SUBMIT_TEXT);
      this.submitLoadingText = this.submitButton.getAttribute(ATTRIBUTES.SUBMIT_LOADING_TEXT)
                               || DEFAULTS.SUBMIT_LOADING_TEXT;
      this.submitButtonOriginalText = this.submitButtonText.textContent;
      return this.submitButton;
    }

    // Prevents the default submission behaviour.
    initSubmitEvent() {
      $(this.form).on(EVENTS.SUBMIT, (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.submitForm();
      });
    }

    // Toggles loading animations on the form.
    toggleFormLoading(loading) {
      /* eslint-disable indent */
      this.submitButtonText.textContent = loading ? this.submitLoadingText
                                                  : this.submitButtonOriginalText;
      this.submitButton.classList[(loading ? 'add' : 'remove')](CLASSES.LOADING);
      this.submitButton[(loading ? 'setAttribute' : 'removeAttribute')](ATTRIBUTES.SUBMIT_DISABLED, '');
      const allInputs = this.form.querySelectorAll(SELECT_QUERIES.FORM_INPUTS);
      for (let i = 0; i < allInputs.length; i += 1) {
        allInputs[i][(loading ? 'setAttribute' : 'removeAttribute')](ATTRIBUTES.SUBMIT_DISABLED, '');
      }
      /* eslint-enable indent */
    }

    // Submit the form via AJAX.
    ajaxSubmit() {
      const $form = $(this.form);
      const formData = $form.serializeArray();
      formData.push({ name: 'url', value: window.location.href });
      const processedData = {};
      for (let i = 0; i < formData.length; i += 1) {
        processedData[formData[i].name] = formData[i].value;
      }
      jQuery.ajax({
        context: this,
        type: 'POST',
        url: this.action,
        contentType: 'application/json',
        data: JSON.stringify(processedData),
        dataType: 'json',
        error: this.showFeedback,
        success: this.processResponse,
      });
      this.toggleFormLoading(true);
    }

    // Hides feedback messages.
    hideFeedback() {
      this.feedback.success.classList.add(CLASSES.HIDE);
      this.feedback.error.classList.add(CLASSES.HIDE);
    }

    // Shows feedback messages.
    showFeedback(status, text) {
      this.toggleFormLoading(false);
      if (typeof status === 'object') {
        clearTimeout(this.feedbackTimeout);
        this.feedback.error.innerHTML = 'There was an error processing the form.';
        this.feedback.error.classList.remove(CLASSES.HIDE);
      } else {
        this.feedback[status].innerHTML = text;
        this.feedback[status].classList.remove(CLASSES.HIDE);
      }
    }

    // Validates and submits the form.
    submitForm() {
      this.hideFeedback();
      if (this.validateForm()) {
        this.ajaxSubmit();
      }
    }

    // Processes the default form validation using Bootstrap.
    validateForm() {
      const formIsValid = this.form.checkValidity();
      if (!formIsValid) {
        clearTimeout(this.feedbackTimeout);
        // Allow BS validation styles to take effect
        this.form.classList.add(CLASSES.WAS_VALIDATED);
        this.showFeedback(STATUS.ERROR, this.feedbackErrorMessage);
        return false;
      }
      this.form.classList.remove(CLASSES.WAS_VALIDATED);
      return true;
    }

    // Process the ajax response from the server.
    processResponse(response) {
      const success = response.status === STATUS.SUCCESS;
      this.toggleFormLoading(false);
      $(this.form).trigger($.Event(EVENTS.SENT));
      // Can't use both of these because the redirect will happen too fast.
      const successDownload = this.form.getAttribute(ATTRIBUTES.SUCCESS_DOWNLOAD);
      const successRedirect = this.form.getAttribute(ATTRIBUTES.SUCCESS_REDIRECT);
      if (success) {
        if (successDownload && successDownload !== '') {
          fetch(successDownload)
            .then((resp) => resp.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = successDownload.split('/').pop().split('?')[0].toString();
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              if (successRedirect && successRedirect !== '' && response.token && response.token !== '') {
                window.location = `${successRedirect}?token=${response.token}`;
              }
            });
        } else if (successRedirect && successRedirect !== '' && response.token && response.token !== '') {
          window.location = `${successRedirect}?token=${response.token}`;
        }
        this.form.reset();
        // Hide all feedback and hold a reference to the timeout to cancel it later.
        this.feedbackTimeout = setTimeout(() => this.hideFeedback(), this.feedbackDelay);
        this.showFeedback(STATUS.SUCCESS, this.feedbackSuccessMessage);
      }
    }

    static getInstanceFromForm(form) {
      if (form && form.nodeType === 1) {
        const data = $(form).data(DATA_KEY);
        return data || null;
      }
      throw new TypeError('Form argument passed to getInstanceFromForm is not an element.');
    }

    static jQueryInterface() {
      return this.each(function jqEachContactForm() {
        const $element = $(this);
        let data = $element.data(DATA_KEY);
        if (!data) {
          data = new ContactForm(this);
          $element.data(DATA_KEY, data);
        }
      });
    }
  }

  // Initialise by data attribute.
  $(window).on(EVENTS.LOAD_DATA_API, () => {
    const ContactFormElements = $.makeArray($(SELECT_QUERIES.FORM));
    /* eslint-disable no-plusplus */
    for (let i = ContactFormElements.length; i--;) {
      const $ContactForm = $(ContactFormElements[i]);
      ContactForm.jQueryInterface.call($ContactForm, $ContactForm.data());
    }
    /* eslint-enable no-plusplus */
  });

  /* eslint-disable no-param-reassign */
  $.fn[NAME] = ContactForm.jQueryInterface;
  $.fn[NAME].Constructor = ContactForm;
  $.fn[NAME].noConflict = function ContactFormNoConflict() {
    $.fn[NAME] = JQUERY_NO_CONFLICT_NAME;
    return ContactForm.jQueryInterface;
  };
  /* eslint-enable no-param-reassign */

  return ContactForm;
})(jQuery);

export default coContactForm;
